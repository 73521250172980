import React from "react";
import { PNLSignal } from './SignalInterface';

interface SignalListProps {
    signals: PNLSignal[];
}

// interface SignalListState {
//     activeIndex: number;
// }

const SignalList = ({ signals }: SignalListProps) => {
    const [activeIndex, setActiveIndex] = React.useState(0)
    const handleActiveSignal = (index: number) => {
        setActiveIndex(index)
    }

    console.log('signals in pnl', signals)

    const renderSignals = (signals: PNLSignal[]) => {
        return (
            <div className="pnl-signal-list-container">
                <table className="table">
                    <tbody>
                        <tr>
                            <th>name</th>
                            <th>ticker</th>
                            <th>interval</th>
                            <th>num_trades</th>
                            <th>first_trade_time</th>
                            <th>last_trade_time</th>
                            <th>pnl</th>
                            <th>pnl_long</th>
                            <th>pnl_short</th>
                            {/* <th>pnl_long_best</th>
                            <th>pnl_long_worst</th>
                            <th>pnl_short_best</th>
                            <th>pnl_short_worst</th> */}
                        </tr>

                        {signals.map((signal, i) => (
                            <tr
                                className={"cursor-pointer " + (i === activeIndex ? "active" : "")}
                                key={i}
                                onClick={() => {
                                    handleActiveSignal(i);
                                }}
                            >
                                <td>{signal.name}</td>
                                <td>{signal.ticker}</td>
                                <td>{signal.interval}</td>
                                <td>{signal.num_trades}</td>
                                <td>{signal.first_trade_time}</td>
                                <td>{signal.last_trade_time}</td>
                                <td>{signal.pnl}</td>
                                <td>{signal.pnl_long}</td>
                                <td>{signal.pnl_short}</td>
                                {/* <td>{signal.pnl_long_best}</td>
                                <td>{signal.pnl_long_worst}</td>
                                <td>{signal.pnl_short_best}</td>
                                <td>{signal.pnl_short_worst}</td> */}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }

    return signals.length > 0 ? (
        renderSignals(signals)
    ) : (
        <div className="alert alert-primary" role="alert">
            No signal to display
        </div>
    );
}

export default SignalList