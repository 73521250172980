import React, { useState, useEffect } from "react";
import axios from "axios";
import SignalList from "./components/SignalList";
import UserPostNoInput from "./components/UserPostNoInput";
import DropdownMenu from "./components/DropdownMenu";
// import MultiDropdownMenu from "./components/MultiDropdownMenu";
import { AggregatedSignal } from "./components/SignalInterface"

import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';

interface AppState {
  signals: AggregatedSignal[];
  signal_collections: { name: string }[];
  data_list: { name: string }[];
  year_list: { name: string }[];
  curr_collection: string;
  curr_signals_length: number;
  curr_year: number;
}

function parseJson(str: string): any {
  try {
    return JSON.parse(str);
  } catch (e) {
    throw e;
  }
}

function parseYear(year: number): any {
  try {
    return year == 0 ? new Date().getFullYear() : year;
  } catch (e) {
    throw e;
  }
}

const App = () => {
  const [appState, setAppState] = useState({
    signals: [],
    signal_collections: [],
    data_list: [],
    year_list: [],
    curr_collection: "",
    curr_signals_length: 0,
    curr_year: 0,
  })

  useEffect(() => {
    axios
      .get("/api/signal-list")
      .then((response) => {
        setAppState((prevState) => {
          return { ...prevState, signal_collections: response.data.map((col_name: string) => ({ name: col_name })) }

        })
      })
      .catch((e) => console.log(e));

    axios
      .get("/api2/data-list")
      .then((response) => {
        setAppState((prevState) => {
          return {
            ...prevState,
            data_list: response.data.map((col_name: string) => ({ name: col_name }))
          }
        })
      })
      .catch((e) => console.log(e));
  }, [])

  const handleSelectedOptionChange = (collection_name: string, year: number) => {
    console.log("handleSelectedOptionChange: " + collection_name);
    axios
      .get(`/api/year-list/${collection_name}`)
      .then((response) => {
        setAppState((prevState) => {
          return {
            ...prevState,
            year_list: response.data.map((yr: string) => ({ name: yr })),
          }
        });
      })
      .catch((e) => console.log(e));

    axios
      .get(`/api/signals`, { params: { "collection": collection_name, "year": parseYear(year) } })
      .then((response) => {
        setAppState((prevState) => {
          return {
            ...prevState,
            signals: response.data.map((jsonstr: string) => parseJson(jsonstr)),
          }
        });
      })
      .catch((e) => console.log(e));
  }

  const handleSaveToDb = (option: { name: string }): void => {
    if (!option) return;
    console.log("handleSaveToDb: " + option.name)
    axios
      .post("/api2/save-to-db", { "filename": option.name })
      .then((response) => {
        return;
      })
      .catch((e) => console.log(e));
  };

  const handleCollectionChange = (option: { name: string }): void => {
    if (!option) return;
    setAppState((prevState) => {
      return {
        ...prevState,
        curr_collection: option.name,
      }
    });
    console.log("collection: " + option.name);
    axios
      .get(`/api/signal-length/${option.name}`)
      .then((response) => {
        setAppState((prevState) => {
          return {
            ...prevState,
            curr_signals_length: parseInt(response.data, 10),
          }
        });
      })
      .catch((e) => console.log(e));
    handleSelectedOptionChange(option.name, appState.curr_year);
  };

  const handleYearChange = (option: { name: string }): void => {
    if (!option) return;
    setAppState((prevState) => {
      return {
        ...prevState,
        curr_year: parseInt(option.name),
      }
    });
    console.log("year: " + option.name);
    handleSelectedOptionChange(appState.curr_collection, parseInt(option.name));
  };

  const handleUpdateIndicator = (): void => {
    axios
      .post("/api2/update-schema")
      .catch((e) => console.log(e));
  };

  // handleGetTestSignals = (): void => {
  //   axios
  //     .get(`/api/signals1`, { params: { "collection": this.state.curr_collection, "year": parseYear(this.state.curr_year) } })
  //     .catch((e) => console.log(e));
  // };


  return (
    <div className="App container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xs-12 col-sm-8 col-md-8 offset-md-2">
            <h1>Signals</h1>
            <div className="signal-app">
              <UserPostNoInput handler={handleUpdateIndicator} name={"Update Indicator Schema"} />
              <DropdownMenu options={appState.data_list} defaultText={"Save data to db"} onSelectedOptionChange={handleSaveToDb} />
              <DropdownMenu options={appState.signal_collections} defaultText={"Select a Collection"} onSelectedOptionChange={handleCollectionChange} />
              <DropdownMenu options={appState.year_list} defaultText={"Select Year"} onSelectedOptionChange={handleYearChange} />
              {/* <UserPostNoInput handler={this.handleGetTestSignals} name={"Get Test Signals"} /> */}
              {/* 
                <div className="card flex justify-content-center">
                  <MultiSelect
                    value={this.state.selected_indicators}
                    onChange={(e: MultiSelectChangeEvent) => this.setState({ selected_indicators: e.value })}
                    options={this.state.indicators}
                    optionLabel="name"
                    filter placeholder="Select Option"
                    maxSelectedLabels={3}
                    className="w-full md:w-20rem" />
                </div> */}
              <SignalList signals={appState.signals} />
              {/* <BasicPaginator totalRecords={this.state.curr_signals_length} onSelectedOptionChange={this.handlePageChange} /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}

export default App;
