import React from "react";
import { LiveSignal } from './SignalInterface';
import dayjs from "dayjs";

import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc'
// if you don't extend utc, timezone plugin will throw errors because it relies on utc!
dayjs.extend(utc)
dayjs.extend(timezone)

interface SignalListProps {
    signals: LiveSignal[];
    selectedTimezone: any;
}

interface SignalListState {
    activeIndex: number;
}

const SignalList = ({ signals, selectedTimezone }: SignalListProps) => {
    const [activeIndex, setActiveIndex] = React.useState(0)
    const handleActiveSignal = (index: number) => {
        setActiveIndex(index)
    }

    const parseAction = (signal: LiveSignal) => {
        if (signal.sentiment === null || signal.sentiment === '') {
            return signal.strategy.order_action
        }
        else {
            const actionId = parseInt(signal.sentiment)
            if (actionId === 1) {
                return "buy"
            } else if (actionId === 0) {
                return "sell"
            }
        }
        return "";
    }

    const changeTimezone = (signalTime: any) => {
        const utcTime = dayjs.utc(signalTime)
        return dayjs.tz(utcTime, selectedTimezone.value ?? selectedTimezone).format('YYYY-MM-DD HH:mm:ss')
    }

    const renderSignals = (signals: LiveSignal[]) => {
        return (
            <div className="container">
                <table className="table">
                    <tbody>
                        <tr>
                            <th>timestamp</th>
                            <th>interval</th>
                            <th>price</th>
                            <th>ticker</th>
                            {/* <th>indicator</th> */}
                            <th>action</th>
                        </tr>

                        {signals.map((signal, i) => (
                            <tr
                                className={"cursor-pointer " + (i === activeIndex ? "active" : "")}
                                key={i}
                                onClick={() => {
                                    handleActiveSignal(i);
                                }}
                            >
                                <td>{changeTimezone(signal.timenow)}</td>
                                <td>{signal.interval}</td>
                                <td>{signal.bar.close}</td>
                                <td>{signal.ticker}</td>
                                {/* <td>{signal.name}</td> */}
                                <td>{parseAction(signal)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }

    return signals.length > 0 ? (
        renderSignals(signals)
    ) : (
        <div className="alert alert-primary" role="alert">
            No signal to display
        </div>
    );
}

export default SignalList