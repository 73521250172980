import React from "react";

interface UserPostNoInputProps {
    handler: () => void;
    name?: string;
}

const UserPostNoInput = ({ handler, name }: UserPostNoInputProps) => {

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        handler()
    };

    return (
        <form noValidate onSubmit={handleSubmit} className="new-signal form-group">
            <button className="btn btn-primary" type="submit">
                {typeof name === "string" && name.length > 0 ? name : ""}
            </button>
        </form>
    )
}

export default UserPostNoInput