import React, { useState, useEffect } from "react";
import ReactDOM from 'react-dom/client';
import './index.css';
import { default as Live } from './modules/live/live';
import { default as History } from './modules/history/history';
import { default as PNL } from './modules/pnl/pnl';
// import reportWebVitals from './reportWebVitals';

import { TabMenu } from "primereact/tabmenu";
import Header from "./Header";

interface BasicTabMenuProps {
  activeIndex: number;
  handleTabChange: (index: number) => void;
}

const BasicTabMenu: React.FC<BasicTabMenuProps> = (props) => {
  const items = [
    { label: "Live" },
    { label: "History" },
    { label: "PNL" },
  ];

  return (
    <div className="card">
      <TabMenu
        model={items}
        activeIndex={props.activeIndex}
        onTabChange={(e) => props.handleTabChange(e.index)}
      />
    </div>
  );
}

const RenderHelper = ({ selectedTimezone }: any) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div>
      <BasicTabMenu activeIndex={activeIndex} handleTabChange={setActiveIndex} />
      {activeIndex === 0 && <Live selectedTimezone={selectedTimezone} />}
      {activeIndex === 1 && <History />}
      {activeIndex === 2 && <PNL />}
    </div>
  );

};

// should shift each component into a separate file, for example App.tsx
const App = () => {
  const [selectedTimezone, setSelectedTimezone] = React.useState<any>(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  )
  return <>
    <Header selectedTimezone={selectedTimezone} setSelectedTimezone={setSelectedTimezone} />
    <RenderHelper selectedTimezone={selectedTimezone} />
  </>
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
