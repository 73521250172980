import React from "react";
import { AggregatedSignal } from "./SignalInterface"

interface SignalListProps {
    signals: AggregatedSignal[];
}

interface SignalListState {
    activeIndex: number;
}

const SignalList = ({ signals }: SignalListProps) => {
    const [activeIndex, setActiveIndex] = React.useState(0)

    const handleActiveSignal = (index: number) => {
        setActiveIndex(index)
    }

    const convertToLocaleTime = (time: number) => {
        return new Date(time * 1000).toLocaleString("en-US");
    }

    const getNumSignal = (action_counts: AggregatedSignal["action_counts"], action: string) => {
        if (!action_counts || !(action in action_counts)) return 0;
        if (!action_counts[action] || !("count" in action_counts[action])) return 0;
        return action_counts[action]["count"];
    }

    const getSignalSource = (action_counts: AggregatedSignal["action_counts"], action: string) => {
        if (!action_counts || !(action in action_counts)) return [];
        if (!action_counts[action] || !("source" in action_counts[action])) return [];
        return action_counts[action]["source"];
    }

    const setText = (stringsList: string[]) => {
        if (stringsList == null || stringsList.length === 0) {
            return "";
        }
        const elements = stringsList.map((str, index) => (
            <React.Fragment key={index}>
                {str}
                {index !== stringsList.length - 1 && <br />}{" "}
            </React.Fragment>
        ));

        return elements;
    }

    const renderSignals = (signals: AggregatedSignal[]) => {
        return (
            <div className="container">
                <table className="table">
                    <tbody>
                        <tr>
                            <th>timestamp</th>
                            <th>buy</th>
                            {/* <th>buy source</th> */}
                            <th>sell</th>
                            {/* <th>sell source</th> */}
                        </tr>

                        {signals.map((signal, i) => (
                            <tr
                                className={"cursor-pointer " + (i === activeIndex ? "active" : "")}
                                key={i}
                                onClick={() => {
                                    handleActiveSignal(i);
                                }}
                            >
                                <td>{convertToLocaleTime(signal.time)}</td>
                                <td>{getNumSignal(signal.action_counts, "1")}</td>
                                {/* <td>{this.setText(this.getSignalSource(signal.action_counts, "1"))}</td> */}
                                <td>{getNumSignal(signal.action_counts, "2")}</td>
                                {/* <td>{this.setText(this.getSignalSource(signal.action_counts, "2"))}</td> */}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }

    return signals.length > 0 ? (
        renderSignals(signals)
    ) : (
        <div className="alert alert-primary" role="alert">
            No signal to display
        </div>
    );
}

export default SignalList;