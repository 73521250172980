import './pnl.css';
import React, { useState, useEffect } from "react";
import axios from "axios";
import SignalList from "./components/SignalList";
import { PNLSignal } from './components/SignalInterface';

// interface AppState {
//   signals: PNLSignal[];
//   signals_length: number;
// }

function parseJson(str: string): PNLSignal {
  try {
    return JSON.parse(str);
  } catch (e) {
    console.log("parseJson:", e);
    console.log(str);
    throw e;
  }
}

// yc: bad naming, should probably rename this to something more relevant than App
const App = () => {
  const [signals, setSignals] = useState<PNLSignal[]>([])
  // const [signalsLength, setSignalsLength] = useState(0)

  useEffect(() => {
    axios
      .get(`/api/pnl`)
      .then((response) => {
        setSignals(response.data.map((jsonstr: string) => parseJson(jsonstr)))
      })
      .catch((e) => console.log(e));
    // mock data for testing UI
    // setSignals([{
    //   name: 'mock',
    //   ticker: 'test ticker',
    //   exchange: 'abcd',
    //   interval: '1',
    //   num_trades: '0',
    //   first_trade_time: 'test time',
    //   last_trade_time: 'test last time',
    //   pnl: '1',
    //   pnl_long: 'pnl long',
    //   pnl_long_best: 'pnl long best',
    //   pnl_long_worst: 'pnl_long_worst',
    //   pnl_short: 'pnl_short',
    //   pnl_short_best: 'pnl short best',
    //   pnl_short_worst: 'pnl short worst'
    // }])
  }, [])

  return (
    <div className="App container">
      <div className="container-fluid">
        <div className="row">
          <div>
            <h1>Live Signals PNL</h1>
            <div className="pnl-container">
              <SignalList signals={signals} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}

export default App;
