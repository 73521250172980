import React, { useState } from "react";
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import { Dropdown } from 'primereact/dropdown';

interface DropdownMenuProps {
    options: { name: string }[];
    defaultText: string;
    onSelectedOptionChange: (option: { name: string }) => void;
}

export default function DropdownMenu(props: DropdownMenuProps) {
    const [selectedOption, setSelectedOption] = useState<{ name: string } | null>(null);

    const handleSelectedOptionChange = (option: { name: string }) => {
        setSelectedOption(option);
        props.onSelectedOptionChange(option);
    };

    return (
        <div className="card flex justify-content-center">
            <Dropdown
                value={selectedOption}
                onChange={(e) => handleSelectedOptionChange(e.value)}
                options={props.options}
                optionLabel="name"
                placeholder={props.defaultText}
                className="w-full md:w-14rem"
                showClear
            />
        </div>
    );
}
