import './live.css';
import React, { useState, useEffect } from "react";
import axios from "axios";
import SignalList from "./components/SignalList";
import UserPost from "./components/UserPost";
import { LiveSignal } from './components/SignalInterface';
import DatePicker from "react-datepicker";
import dayjs from 'dayjs';

import "react-datepicker/dist/react-datepicker.css";

interface AppState {
  signals: LiveSignal[];
  signals_length: number;
}

function parseJson(str: string): LiveSignal {
  try {
    return JSON.parse(str);
  } catch (e) {
    console.log("parseJson:", e);
    console.log(str);
    throw e;
  }
}

// yc: bad naming, should probably rename this to something more relevant than App
const App = ({ selectedTimezone }: any) => {
  const [signals, setSignals] = useState<LiveSignal[]>([])
  const [signalsLength, setSignalsLength] = useState(0)
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    axios
      .get(`/api/live-signal-length`)
      .then((response) => {
        setSignalsLength(parseInt(response.data, 10));
      })
      .catch((e) => console.log(e));

    // commented out because the /dates API should return filtered data instead?
    // axios
    //   .get(`/api/live-signals`)
    //   .then((response) => {
    //     setSignals(response.data.map((jsonstr: string) => parseJson(jsonstr)),
    //     );
    //   })
    //   .catch((e) => console.log(e));
  }, [])

  useEffect(() => {
    const startDate = dayjs(dayjs(selectedDate).format('YYYY-MM-DD')).format('YYYY-MM-DDTHH:mm:ss')
    axios
      .get(`/api/live-signals`, {
        params: {
          start: startDate,
          stop: selectedDate
        }
      })
      .then((response) => {
        setSignals(response.data.map((jsonstr: string) => parseJson(jsonstr)))
      })
      .catch((e) => console.log(e));
  }, [selectedDate])

  // yc: need to move this out of the component? or ideally just memoize it
  const handleAddSignal = (value: string) => {
    console.log(value);
    var signalObject = parseJson(value);
    axios
      .post("/api/tradingview-webhook", signalObject)
      .then((response) => {
        setSignals((prevState: any) => { return [signalObject, ...prevState] });
      })
      .catch((e) => console.log(e));
  };


  return (
    <div className="App container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xs-12 col-sm-8 col-md-8 offset-md-2">
            <div>
              <span>Filter By Date: </span>
              <DatePicker selected={selectedDate} onChange={(date: Date) => setSelectedDate(date)} />
            </div>
            <h1>Live Signals</h1>
            <div className="signal-app">
              <UserPost handler={handleAddSignal} name={"Add Signal"} />
              <SignalList signals={signals} selectedTimezone={selectedTimezone} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}

export default App;
