import React, { ChangeEvent } from "react";

interface UserPostProps {
  handler: (value: string) => void;
  name?: string;
  rows?: number;
  cols?: number;
}

interface UserPostFormState {
  value: string;
}

const UserPost = ({ handler, name, rows, cols }: UserPostProps) => {
  const [value, setValue] = React.useState("")

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log(value);
    handler(value);
    setValue("");
  };

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  };


  return (
    <form
      noValidate
      onSubmit={handleSubmit}
      className="new-signal form-group"
    >
      <textarea
        value={value}
        onChange={handleChange}
        required
        minLength={1}
        className="form-control"
        rows={typeof rows === "number" ? rows : 1}
        cols={typeof cols === "number" ? cols : 20}
      />
      <button className="btn btn-primary" type="submit">
        {typeof name === "string" && name.length > 0
          ? name
          : "Submit"}
      </button>
    </form>
  );
}

export default UserPost