import React from "react"
import TimezoneSelect from 'react-timezone-select'
import "./Header.css"

const Header = ({ selectedTimezone, setSelectedTimezone }: any) => {
    const onSelectTimezone = (data: any) => {
        console.log(data, "data")
        setSelectedTimezone(data)
    }

    return (
        <div className="header">
            <div className="select-wrapper">
                <TimezoneSelect
                    value={selectedTimezone}
                    onChange={onSelectTimezone}
                />
            </div>
        </div>
    )
}

export default Header